export let days = [   
    "Bazar",
    "Bazar ertəsi",
    "Çərşənbə axşamı",
    "Çərşənbə",
    "Cümə axşamı",
    "Cümə",
    "Şənbə"
]
export let month_names = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "İyun",
    "İyul",
    "Avqust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr"
]
export let hijri_months = [
    "Məhərrəm",
    "Səfər",
    "Rəbiüləvvəl",
    "Rəbiüləhir",
    "Cəmadiyələvvəl",
    "Cəmadiyələhir",
    "Rəcəb",
    "Şaban",
    "Ramazan",
    "Şəvval",
    "Zilqədə",
    "Zilhicce"
];


export let cities=[
    "Qax",
    "Zaqatala",
    "Beyləqan",
    "Göyçay",
    "Göygöl",
    "Naftalan",
    "Goranboy",
    "İsmayıllı",
    "Yevlax",
    "Ağdaş",
    "Oğuz",
    "Şəki",
    "Samux",
    "Balakən",
    "Şəmkir",
    "Daşkəsən",
    "Gədəbəy",
    "Tovuz",
    "Ağstafa",
    "Zərdab",
    "Qusar",
    "Xaçmaz",
    "Quba",
    "Şabran",
    "Siyəzən",
    "Tərtər",
    "Şamaxı",
    "Ağsu",
    "Kürdəmir",
    "İmişli",
    "Biləsuvar",
    "Saatlı",
    "Sabirabad",
    "Şirvan",
    "Hacıqabul",
    "Salyan",
    "Cəlilabad",
    "Yardımlı",
    "Lerik",
    "Astara",
    "Masallı",
    "Neftçala",
    "Lənkəran",
    "Xocalı",
    "Laçın",
    "Qubadlı",
    "Zəngilan",
    "Cəbrayıl",
    "Füzuli",
    "Qazax",
    "Nizami(Gəncə)",
    "Kəpəz(Gəncə)",
    "Sumqayıt",
    "Binəqədi",
    "Nərimanov",
    "Nəsimi",
    "Qəbələ",
    "Şərur",
    "Şahbuz",
    "Sədərək",
    "Ordubad",
    "Naxçıvan",
    "Kəngərli",
    "Culfa",
    "Nizami",
    "Pirallahı",
    "Qaradağ",
    "Sabunçu",
    "Səbail",
    "Suraxanı",
    "Xətai",
    "Xəzər",
    "Yasamal",
    "Abşeron",
    "Babək",
    "Ağcabədi",
    "Ağdam",
    "Mingəçevir",
    "Xızı",
    "Qobustan",
    "Ucar",
    "Bərdə",
    "Şuşa",
    "Kəlbəcər",
    "Xankəndi",
    "Xocavənd"
]